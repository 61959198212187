export const Card = (props) => {
    return (
        // <div className="w-full lg:w-4/12 md:w-5/12 h-fit shadow-xl rounded-3xl p-5">
        <div className="h-fit shadow-xl rounded-3xl p-5">
            {props.children}
        </div>
    )
}









