import { Fragment, useState } from 'react';
import { Dialog, Disclosure, Menu, Transition } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Product } from './Product';
import firstImage from '../../../../assets/1.jpg'
import secondImage from '../../../../assets/2.jpg'
import thirdImage from '../../../../assets/3.jpg'
import forthImage from '../../../../assets/4.jpg'
import fifthImage from '../../../../assets/5.jpg'
import sisthImage from '../../../../assets/6.jpg'
import seventhImage from '../../../../assets/14.jpg'
import eigththImage from '../../../../assets/15.jpg'
const sortOptions = [
  { name: 'Most Popular', href: '/', current: true },
  { name: 'Best Rating', href: '/', current: false },
  { name: 'Newest', href: '/', current: false },
  { name: 'Price: Low to High', href: '/', current: false },
  { name: 'Price: High to Low', href: '/', current: false },
]
const subCategories = [
  { name: 'Totes', href: '/' },
  { name: 'Backpacks', href: '/' },
  { name: 'Travel Bags', href: '/' },
  { name: 'Hip Bags', href: '/' },
  { name: 'Laptop Sleeves', href: '/' },
]
const filters = [
  {
    id: 'color',
    name: 'Color',
    options: [
      { value: 'white', label: 'White', checked: false },
      { value: 'beige', label: 'Beige', checked: false },
      { value: 'blue', label: 'Blue', checked: true },
      { value: 'brown', label: 'Brown', checked: false },
      { value: 'green', label: 'Green', checked: false },
      { value: 'purple', label: 'Purple', checked: false },
    ],
  },
  {
    id: 'category',
    name: 'Category',
    options: [
      { value: 'new-arrivals', label: 'New Arrivals', checked: false },
      { value: 'sale', label: 'Sale', checked: false },
      { value: 'travel', label: 'Travel', checked: true },
      { value: 'organization', label: 'Organization', checked: false },
      { value: 'accessories', label: 'Accessories', checked: false },
    ],
  },
  {
    id: 'size',
    name: 'Size',
    options: [
      { value: '2l', label: '2L', checked: false },
      { value: '6l', label: '6L', checked: false },
      { value: '12l', label: '12L', checked: false },
      { value: '18l', label: '18L', checked: false },
      { value: '20l', label: '20L', checked: false },
      { value: '40l', label: '40L', checked: true },
    ],
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export const ProductFilters = () => {
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false)

  return (
    // <div className="bg-white">
    //   <div>
    //     {/* Mobile filter dialog */}
    //     <Transition.Root show={mobileFiltersOpen} as={Fragment}>
    //       <Dialog as="div" className="relative z-40 lg:hidden" onClose={setMobileFiltersOpen}>
    //         <Transition.Child
    //           as={Fragment}
    //           enter="transition-opacity ease-linear duration-300"
    //           enterFrom="opacity-0"
    //           enterTo="opacity-100"
    //           leave="transition-opacity ease-linear duration-300"
    //           leaveFrom="opacity-100"
    //           leaveTo="opacity-0"
    //         >
    //           <div className="fixed inset-0 bg-black bg-opacity-25" />
    //         </Transition.Child>

    //         <div className="fixed inset-0 z-40 flex">
    //           <Transition.Child
    //             as={Fragment}
    //             enter="transition ease-in-out duration-300 transform"
    //             enterFrom="translate-x-full"
    //             enterTo="translate-x-0"
    //             leave="transition ease-in-out duration-300 transform"
    //             leaveFrom="translate-x-0"
    //             leaveTo="translate-x-full"
    //           >
    //             <Dialog.Panel className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-12 shadow-xl">
    //               <div className="flex items-center justify-between px-4">
    //                 <h2 className="text-lg font-medium text-gray-900">Filters</h2>
    //                 <button
    //                   type="button"
    //                   className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400"
    //                   onClick={() => setMobileFiltersOpen(false)}
    //                 >
    //                   <span className="sr-only">Close menu</span>
    //                   <FontAwesomeIcon icon="fa-solid fa-xmark" className="h-6 w-6" aria-hidden="true" />
    //                 </button>
    //               </div>

    //               {/* Filters */}
    //               <form className="mt-4 border-t border-gray-200">
    //                 <h3 className="sr-only">Categories</h3>
    //                 <ul  className="px-2 py-3 font-medium text-gray-900">
    //                   {subCategories.map((category) => (
    //                     <li key={category.name}>
    //                       <a href={category.href} className="block px-2 py-3">
    //                         {category.name}
    //                       </a>
    //                     </li>
    //                   ))}
    //                 </ul>

    //                 {filters.map((section) => (
    //                   <Disclosure as="div" key={section.id} className="border-t border-gray-200 px-4 py-6">
    //                     {({ open }) => (
    //                       <>
    //                         <h3 className="-mx-2 -my-3 flow-root">
    //                           <Disclosure.Button className="flex w-full items-center justify-between bg-white px-2 py-3 text-gray-400 hover:text-gray-500">
    //                             <span className="font-medium text-gray-900">{section.name}</span>
    //                             <span className="ml-6 flex items-center">
    //                               {open ? (
    //                                 <FontAwesomeIcon icon="fa-solid fa-minus" className="h-4 w-4" aria-hidden="true" />
    //                               ) : (
    //                                 <FontAwesomeIcon icon="fa-solid fa-plus" className="h-4 w-4" aria-hidden="true" />
    //                               )}
    //                             </span>
    //                           </Disclosure.Button>
    //                         </h3>
    //                         <Disclosure.Panel className="pt-6">
    //                           <div className="space-y-6">
    //                             {section.options.map((option, optionIdx) => (
    //                               <div key={option.value} className="flex items-center">
    //                                 <input
    //                                   id={`filter-mobile-${section.id}-${optionIdx}`}
    //                                   name={`${section.id}[]`}
    //                                   defaultValue={option.value}
    //                                   type="checkbox"
    //                                   defaultChecked={option.checked}
    //                                   className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
    //                                 />
    //                                 <label
    //                                   htmlFor={`filter-mobile-${section.id}-${optionIdx}`}
    //                                   className="ml-3 min-w-0 flex-1 text-gray-500"
    //                                 >
    //                                   {option.label}
    //                                 </label>
    //                               </div>
    //                             ))}
    //                           </div>
    //                         </Disclosure.Panel>
    //                       </>
    //                     )}
    //                   </Disclosure>
    //                 ))}
    //               </form>
    //             </Dialog.Panel>
    //           </Transition.Child>
    //         </div>
    //       </Dialog>
    //     </Transition.Root>

    //     <main className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
    //       <div className="flex items-baseline justify-between border-b border-gray-200 pb-6 pt-24">
    //         <h1 className="text-4xl font-bold tracking-tight text-gray-900">New Arrivals</h1>

    //         <div className="flex items-center">
    //           <Menu as="div" className="relative inline-block text-left">
    //             <div>
    //               <Menu.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
    //                 Sort               
    //                 <FontAwesomeIcon
    //                     className="-mr-1 ml-1 h-4 w-4 flex-shrink-0 self-center text-gray-400 group-hover:text-gray-500"
    //                     aria-hidden="true"
    //                     icon="fa-solid fa-chevron-down"  
    //                 />
    //               </Menu.Button>
    //             </div>

    //             <Transition
    //               as={Fragment}
    //               enter="transition ease-out duration-100"
    //               enterFrom="transform opacity-0 scale-95"
    //               enterTo="transform opacity-100 scale-100"
    //               leave="transition ease-in duration-75"
    //               leaveFrom="transform opacity-100 scale-100"
    //               leaveTo="transform opacity-0 scale-95"
    //             >
    //               <Menu.Items className="absolute right-0 z-10 mt-2 w-40 origin-top-right rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
    //                 <div className="py-1">
    //                   {sortOptions.map((option) => (
    //                     <Menu.Item key={option.name}>
    //                       {({ active }) => (
    //                         <a
    //                           href={option.href}
    //                           className={classNames(
    //                             option.current ? 'font-medium text-gray-900' : 'text-gray-500',
    //                             active ? 'bg-gray-100' : '',
    //                             'block px-4 py-2 text-sm'
    //                           )}
    //                         >
    //                           {option.name}
    //                         </a>
    //                       )}
    //                     </Menu.Item>
    //                   ))}
    //                 </div>
    //               </Menu.Items>
    //             </Transition>
    //           </Menu>

    //           <button type="button" className="-m-2 ml-5 p-2 text-gray-400 hover:text-gray-500 sm:ml-7">
    //             <span className="sr-only">View grid</span>
    //             <FontAwesomeIcon icon="fa-solid fa-border-all" className="h-4 w-4" aria-hidden="true" />
    //           </button>
    //           <button
    //             type="button"
    //             className="-m-2 ml-4 p-2 text-gray-400 hover:text-gray-500 sm:ml-6 lg:hidden"
    //             onClick={() => setMobileFiltersOpen(true)}
    //           >
    //             <span className="sr-only">Filters</span>
    //             <FontAwesomeIcon icon="fa-solid fa-filter" className="h-4 w-4 " aria-hidden="true" />
    //           </button>
    //         </div>
    //       </div>

    //       <section aria-labelledby="products-heading" className="pb-24 pt-6">
    //         <h2 id="products-heading" className="sr-only">
    //           Products
    //         </h2>

    //         <div className="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-4">
    //           {/* Filters */}
    //           <form className="hidden lg:block">
    //             <h3 className="sr-only">Categories</h3>
    //             <ul className="space-y-4 border-b border-gray-200 pb-6 text-sm font-medium text-gray-900">
    //               {subCategories.map((category) => (
    //                 <li key={category.name}>
    //                   <a href={category.href}>{category.name}</a>
    //                 </li>
    //               ))}
    //             </ul>

    //             {filters.map((section) => (
    //               <Disclosure as="div" key={section.id} className="border-b border-gray-200 py-6">
    //                 {({ open }) => (
    //                   <>
    //                     <h3 className="-my-3 flow-root">
    //                       <Disclosure.Button className="flex w-full items-center justify-between bg-white py-3 text-sm text-gray-400 hover:text-gray-500">
    //                         <span className="font-medium text-gray-900">{section.name}</span>
    //                         <span className="ml-6 flex items-center">
    //                           {open ? (
    //                             <FontAwesomeIcon icon="fa-solid fa-minus" className="h-4 w-4" aria-hidden="true" />
    //                           ) : (
    //                             <FontAwesomeIcon icon="fa-solid fa-plus" className="h-4 w-4" aria-hidden="true" />
    //                           )}
    //                         </span>
    //                       </Disclosure.Button>
    //                     </h3>
    //                     <Disclosure.Panel className="pt-6">
    //                       <div className="space-y-4">
    //                         {section.options.map((option, optionIdx) => (
    //                           <div key={option.value} className="flex items-center">
    //                             <input
    //                               id={`filter-${section.id}-${optionIdx}`}
    //                               name={`${section.id}[]`}
    //                               defaultValue={option.value}
    //                               type="checkbox"
    //                               defaultChecked={option.checked}
    //                               className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
    //                             />
    //                             <label
    //                               htmlFor={`filter-${section.id}-${optionIdx}`}
    //                               className="ml-3 text-sm text-gray-600"
    //                             >
    //                               {option.label}
    //                             </label>
    //                           </div>
    //                         ))}
    //                       </div>
    //                     </Disclosure.Panel>
    //                   </>
    //                 )}
    //               </Disclosure>
    //             ))}
    //           </form>

    //           {/* Product grid */}
    //             <div className="lg:col-span-3">
    //                {/* <Product />            */}
    //             </div>
    //         </div>
    //       </section>
    //     </main>
    //   </div>
    // </div>
    <section className="relative md:py-24 py-16 px-10 md:px-24">
  <div className="container">
    <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
      <div className="lg:col-span-9 md:col-span-8">
        <h3 className="text-xl leading-normal font-semibold">
          Showing 1-8 of 16 results
        </h3>
      </div>
      <div className="lg:col-span-3 md:col-span-4 md:text-end">
        <label className="font-semibold hidden" />
        <select className="form-select form-input w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0">
          <option selected="">Sort by latest</option>
          <option>Sort by popularity</option>
          <option>Sort by rating</option>
          <option>Sort by price: low to high</option>
          <option>Sort by price: high to low</option>
        </select>
      </div>
    </div>
    {/*end grid*/}
    <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 mt-8 gap-[30px]">
      <div className="group">
        <div className="relative overflow-hidden shadow dark:shadow-gray-800 group-hover:shadow-lg group-hover:dark:shadow-gray-800 rounded-md transition-all duration-500">
          <img src={firstImage} alt="" />
          <div className="absolute -bottom-20 group-hover:bottom-3 start-3 end-3 transition-all duration-500">
            <a
              href="shop-cart.html"
              className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-slate-900 border-slate-900 text-white w-full rounded-md"
            >
              Add to Cart
            </a>
          </div>
          <ul className="list-none absolute top-[10px] end-4 opacity-0 group-hover:opacity-100 transition-all duration-500">
            <li>
              <a
                href="javascript:void(0)"
                className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-base text-center rounded-full bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white"
              >
                    <FontAwesomeIcon icon='fa-solid fa-heart' />
              </a>
            </li>
            <li className="mt-1">
              <a
                href="shop-item-detail.html"
                className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-base text-center rounded-full bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white"
              >
              <FontAwesomeIcon icon='fa-solid fa-eye' />
              </a>
            </li>
            <li className="mt-1">
              <a
                href="javascript:void(0)"
                className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-base text-center rounded-full bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white"
              >
              <FontAwesomeIcon icon='fa-solid fa-bookmark' />
              </a>
            </li>
          </ul>
        </div>
        <div className="mt-4">
          <a
            href="shop-item-detail.html"
            className="hover:text-indigo-600 text-lg font-semibold"
          >
            Pasta
          </a>
          <div className="flex justify-between items-center mt-1">
            <p className="text-green-600">
              $16.00 <del className="text-red-600">$21.00</del>
            </p>
            {/* <ul className="list-none absolute top-[10px] end-4 opacity-0 group-hover:opacity-100 transition-all duration-500">
            <li>
              <a
                href="javascript:void(0)"
                className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-base text-center rounded-full bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white"
              >
                    <FontAwesomeIcon icon='fa-solid fa-heart' />
              </a>
            </li>
            <li className="mt-1">
              <a
                href="shop-item-detail.html"
                className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-base text-center rounded-full bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white"
              >
              <FontAwesomeIcon icon='fa-solid fa-eye' />
              </a>
            </li>
            <li className="mt-1">
              <a
                href="javascript:void(0)"
                className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-base text-center rounded-full bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white"
              >
              <FontAwesomeIcon icon='fa-solid fa-bookmark' />
              </a>
            </li>
          </ul> */}
          </div>
        </div>
      </div>
      {/*end content*/}
      <div className="group">
      <div className="relative overflow-hidden shadow dark:shadow-gray-800 group-hover:shadow-lg group-hover:dark:shadow-gray-800 rounded-md transition-all duration-500">
          <img src={secondImage} alt="" />
          <div className="absolute -bottom-20 group-hover:bottom-3 start-3 end-3 transition-all duration-500">
            <a
              href="shop-cart.html"
              className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-slate-900 border-slate-900 text-white w-full rounded-md"
            >
              Add to Cart
            </a>
          </div>
          <ul className="list-none absolute top-[10px] end-4 opacity-0 group-hover:opacity-100 transition-all duration-500">
            <li>
              <a
                href="javascript:void(0)"
                className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-base text-center rounded-full bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white"
              >
                    <FontAwesomeIcon icon='fa-solid fa-heart' />
              </a>
            </li>
            <li className="mt-1">
              <a
                href="shop-item-detail.html"
                className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-base text-center rounded-full bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white"
              >
              <FontAwesomeIcon icon='fa-solid fa-eye' />
              </a>
            </li>
            <li className="mt-1">
              <a
                href="javascript:void(0)"
                className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-base text-center rounded-full bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white"
              >
              <FontAwesomeIcon icon='fa-solid fa-bookmark' />
              </a>
            </li>
          </ul>
        </div>
        <div className="mt-4">
          <a
            href="shop-item-detail.html"
            className="hover:text-indigo-600 text-lg font-semibold"
          >
            Dasi Daal
          </a>
          <div className="flex justify-between items-center mt-1">
            <p className="text-green-600">
              $16.00 <del className="text-red-600">$21.00</del>
            </p>
            {/* <ul className="list-none absolute top-[10px] end-4 opacity-0 group-hover:opacity-100 transition-all duration-500">
            <li>
              <a
                href="javascript:void(0)"
                className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-base text-center rounded-full bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white"
              >
                    <FontAwesomeIcon icon='fa-solid fa-heart' />
              </a>
            </li>
            <li className="mt-1">
              <a
                href="shop-item-detail.html"
                className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-base text-center rounded-full bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white"
              >
              <FontAwesomeIcon icon='fa-solid fa-eye' />
              </a>
            </li>
            <li className="mt-1">
              <a
                href="javascript:void(0)"
                className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-base text-center rounded-full bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white"
              >
              <FontAwesomeIcon icon='fa-solid fa-bookmark' />
              </a>
            </li>
          </ul> */}
          </div>
        </div>
      </div>
      {/*end content*/}
      <div className="group">
      <div className="relative overflow-hidden shadow dark:shadow-gray-800 group-hover:shadow-lg group-hover:dark:shadow-gray-800 rounded-md transition-all duration-500">
          <img src={thirdImage} alt="" />
          <div className="absolute -bottom-20 group-hover:bottom-3 start-3 end-3 transition-all duration-500">
            <a
              href="shop-cart.html"
              className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-slate-900 border-slate-900 text-white w-full rounded-md"
            >
              Add to Cart
            </a>
          </div>
          <ul className="list-none absolute top-[10px] end-4 opacity-0 group-hover:opacity-100 transition-all duration-500">
            <li>
              <a
                href="javascript:void(0)"
                className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-base text-center rounded-full bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white"
              >
                    <FontAwesomeIcon icon='fa-solid fa-heart' />
              </a>
            </li>
            <li className="mt-1">
              <a
                href="shop-item-detail.html"
                className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-base text-center rounded-full bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white"
              >
              <FontAwesomeIcon icon='fa-solid fa-eye' />
              </a>
            </li>
            <li className="mt-1">
              <a
                href="javascript:void(0)"
                className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-base text-center rounded-full bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white"
              >
              <FontAwesomeIcon icon='fa-solid fa-bookmark' />
              </a>
            </li>
          </ul>
        </div>
        <div className="mt-4">
          <a
            href="shop-item-detail.html"
            className="hover:text-indigo-600 text-lg font-semibold"
          >
            White Rise
          </a>
          <div className="flex justify-between items-center mt-1">
            <p className="text-green-600">
              $16.00 <del className="text-red-600">$21.00</del>
            </p>
            {/* <ul className="list-none absolute top-[10px] end-4 opacity-0 group-hover:opacity-100 transition-all duration-500">
            <li>
              <a
                href="javascript:void(0)"
                className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-base text-center rounded-full bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white"
              >
                    <FontAwesomeIcon icon='fa-solid fa-heart' />
              </a>
            </li>
            <li className="mt-1">
              <a
                href="shop-item-detail.html"
                className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-base text-center rounded-full bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white"
              >
              <FontAwesomeIcon icon='fa-solid fa-eye' />
              </a>
            </li>
            <li className="mt-1">
              <a
                href="javascript:void(0)"
                className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-base text-center rounded-full bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white"
              >
              <FontAwesomeIcon icon='fa-solid fa-bookmark' />
              </a>
            </li>
          </ul> */}
          </div>
        </div>
      </div>
      {/*end content*/}
      <div className="group">
      <div className="relative overflow-hidden shadow dark:shadow-gray-800 group-hover:shadow-lg group-hover:dark:shadow-gray-800 rounded-md transition-all duration-500">
          <img src={forthImage} alt="" />
          <div className="absolute -bottom-20 group-hover:bottom-3 start-3 end-3 transition-all duration-500">
            <a
              href="shop-cart.html"
              className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-slate-900 border-slate-900 text-white w-full rounded-md"
            >
              Add to Cart
            </a>
          </div>
          <ul className="list-none absolute top-[10px] end-4 opacity-0 group-hover:opacity-100 transition-all duration-500">
            <li>
              <a
                href="javascript:void(0)"
                className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-base text-center rounded-full bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white"
              >
                    <FontAwesomeIcon icon='fa-solid fa-heart' />
              </a>
            </li>
            <li className="mt-1">
              <a
                href="shop-item-detail.html"
                className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-base text-center rounded-full bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white"
              >
              <FontAwesomeIcon icon='fa-solid fa-eye' />
              </a>
            </li>
            <li className="mt-1">
              <a
                href="javascript:void(0)"
                className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-base text-center rounded-full bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white"
              >
              <FontAwesomeIcon icon='fa-solid fa-bookmark' />
              </a>
            </li>
          </ul>
        </div>
        <div className="mt-4">
          <a
            href="shop-item-detail.html"
            className="hover:text-indigo-600 text-lg font-semibold"
          >
            Egg White
          </a>
          <div className="flex justify-between items-center mt-1">
            <p className="text-green-600">
              $16.00 <del className="text-red-600">$21.00</del>
            </p>
            {/* <ul className="list-none absolute top-[10px] end-4 opacity-0 group-hover:opacity-100 transition-all duration-500">
            <li>
              <a
                href="javascript:void(0)"
                className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-base text-center rounded-full bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white"
              >
                    <FontAwesomeIcon icon='fa-solid fa-heart' />
              </a>
            </li>
            <li className="mt-1">
              <a
                href="shop-item-detail.html"
                className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-base text-center rounded-full bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white"
              >
              <FontAwesomeIcon icon='fa-solid fa-eye' />
              </a>
            </li>
            <li className="mt-1">
              <a
                href="javascript:void(0)"
                className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-base text-center rounded-full bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white"
              >
              <FontAwesomeIcon icon='fa-solid fa-bookmark' />
              </a>
            </li>
          </ul> */}
          </div>
        </div>
      </div>
      {/*end content*/}
      <div className="group">
      <div className="relative overflow-hidden shadow dark:shadow-gray-800 group-hover:shadow-lg group-hover:dark:shadow-gray-800 rounded-md transition-all duration-500">
          <img src={fifthImage} alt="" />
          <div className="absolute -bottom-20 group-hover:bottom-3 start-3 end-3 transition-all duration-500">
            <a
              href="shop-cart.html"
              className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-slate-900 border-slate-900 text-white w-full rounded-md"
            >
              Add to Cart
            </a>
          </div>
          <ul className="list-none absolute top-[10px] end-4 opacity-0 group-hover:opacity-100 transition-all duration-500">
            <li>
              <a
                href="javascript:void(0)"
                className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-base text-center rounded-full bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white"
              >
                    <FontAwesomeIcon icon='fa-solid fa-heart' />
              </a>
            </li>
            <li className="mt-1">
              <a
                href="shop-item-detail.html"
                className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-base text-center rounded-full bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white"
              >
              <FontAwesomeIcon icon='fa-solid fa-eye' />
              </a>
            </li>
            <li className="mt-1">
              <a
                href="javascript:void(0)"
                className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-base text-center rounded-full bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white"
              >
              <FontAwesomeIcon icon='fa-solid fa-bookmark' />
              </a>
            </li>
          </ul>
        </div>
        <div className="mt-4">
          <a
            href="shop-item-detail.html"
            className="hover:text-indigo-600 text-lg font-semibold"
          >
            Salty Special
          </a>
          <div className="flex justify-between items-center mt-1">
            <p className="text-green-600">
              $16.00 <del className="text-red-600">$21.00</del>
            </p>
            {/* <ul className="list-none absolute top-[10px] end-4 opacity-0 group-hover:opacity-100 transition-all duration-500">
            <li>
              <a
                href="javascript:void(0)"
                className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-base text-center rounded-full bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white"
              >
                    <FontAwesomeIcon icon='fa-solid fa-heart' />
              </a>
            </li>
            <li className="mt-1">
              <a
                href="shop-item-detail.html"
                className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-base text-center rounded-full bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white"
              >
              <FontAwesomeIcon icon='fa-solid fa-eye' />
              </a>
            </li>
            <li className="mt-1">
              <a
                href="javascript:void(0)"
                className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-base text-center rounded-full bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white"
              >
              <FontAwesomeIcon icon='fa-solid fa-bookmark' />
              </a>
            </li>
          </ul> */}
          </div>
        </div>
      </div>
      {/*end content*/}
      <div className="group">
      <div className="relative overflow-hidden shadow dark:shadow-gray-800 group-hover:shadow-lg group-hover:dark:shadow-gray-800 rounded-md transition-all duration-500">
          <img src={sisthImage} alt="" />
          <div className="absolute -bottom-20 group-hover:bottom-3 start-3 end-3 transition-all duration-500">
            <a
              href="shop-cart.html"
              className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-slate-900 border-slate-900 text-white w-full rounded-md"
            >
              Add to Cart
            </a>
          </div>
          <ul className="list-none absolute top-[10px] end-4 opacity-0 group-hover:opacity-100 transition-all duration-500">
            <li>
              <a
                href="javascript:void(0)"
                className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-base text-center rounded-full bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white"
              >
                    <FontAwesomeIcon icon='fa-solid fa-heart' />
              </a>
            </li>
            <li className="mt-1">
              <a
                href="shop-item-detail.html"
                className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-base text-center rounded-full bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white"
              >
              <FontAwesomeIcon icon='fa-solid fa-eye' />
              </a>
            </li>
            <li className="mt-1">
              <a
                href="javascript:void(0)"
                className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-base text-center rounded-full bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white"
              >
              <FontAwesomeIcon icon='fa-solid fa-bookmark' />
              </a>
            </li>
          </ul>
        </div>
        <div className="mt-4">
          <a
            href="shop-item-detail.html"
            className="hover:text-indigo-600 text-lg font-semibold"
          >
            Grilled Onions
          </a>
          <div className="flex justify-between items-center mt-1">
            <p className="text-green-600">
              $16.00 <del className="text-red-600">$21.00</del>
            </p>
            {/* <ul className="list-none absolute top-[10px] end-4 opacity-0 group-hover:opacity-100 transition-all duration-500">
            <li>
              <a
                href="javascript:void(0)"
                className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-base text-center rounded-full bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white"
              >
                    <FontAwesomeIcon icon='fa-solid fa-heart' />
              </a>
            </li>
            <li className="mt-1">
              <a
                href="shop-item-detail.html"
                className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-base text-center rounded-full bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white"
              >
              <FontAwesomeIcon icon='fa-solid fa-eye' />
              </a>
            </li>
            <li className="mt-1">
              <a
                href="javascript:void(0)"
                className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-base text-center rounded-full bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white"
              >
              <FontAwesomeIcon icon='fa-solid fa-bookmark' />
              </a>
            </li>
          </ul> */}
          </div>
        </div>
      </div>
      {/*end content*/}
      <div className="group">
      <div className="relative overflow-hidden shadow dark:shadow-gray-800 group-hover:shadow-lg group-hover:dark:shadow-gray-800 rounded-md transition-all duration-500">
          <img src={seventhImage} alt="" />
          <div className="absolute -bottom-20 group-hover:bottom-3 start-3 end-3 transition-all duration-500">
            <a
              href="shop-cart.html"
              className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-slate-900 border-slate-900 text-white w-full rounded-md"
            >
              Add to Cart
            </a>
          </div>
          <ul className="list-none absolute top-[10px] end-4 opacity-0 group-hover:opacity-100 transition-all duration-500">
            <li>
              <a
                href="javascript:void(0)"
                className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-base text-center rounded-full bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white"
              >
                    <FontAwesomeIcon icon='fa-solid fa-heart' />
              </a>
            </li>
            <li className="mt-1">
              <a
                href="shop-item-detail.html"
                className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-base text-center rounded-full bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white"
              >
              <FontAwesomeIcon icon='fa-solid fa-eye' />
              </a>
            </li>
            <li className="mt-1">
              <a
                href="javascript:void(0)"
                className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-base text-center rounded-full bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white"
              >
              <FontAwesomeIcon icon='fa-solid fa-bookmark' />
              </a>
            </li>
          </ul>
        </div>
        <div className="mt-4">
          <a
            href="shop-item-detail.html"
            className="hover:text-indigo-600 text-lg font-semibold"
          >
            Kheer
          </a>
          <div className="flex justify-between items-center mt-1">
            <p className="text-green-600">
              $16.00 <del className="text-red-600">$21.00</del>
            </p>
            {/* <ul className="list-none absolute top-[10px] end-4 opacity-0 group-hover:opacity-100 transition-all duration-500">
            <li>
              <a
                href="javascript:void(0)"
                className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-base text-center rounded-full bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white"
              >
                    <FontAwesomeIcon icon='fa-solid fa-heart' />
              </a>
            </li>
            <li className="mt-1">
              <a
                href="shop-item-detail.html"
                className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-base text-center rounded-full bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white"
              >
              <FontAwesomeIcon icon='fa-solid fa-eye' />
              </a>
            </li>
            <li className="mt-1">
              <a
                href="javascript:void(0)"
                className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-base text-center rounded-full bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white"
              >
              <FontAwesomeIcon icon='fa-solid fa-bookmark' />
              </a>
            </li>
          </ul> */}
          </div>
        </div>
      </div>
      {/*end content*/}
      <div className="group">
      <div className="relative overflow-hidden shadow dark:shadow-gray-800 group-hover:shadow-lg group-hover:dark:shadow-gray-800 rounded-md transition-all duration-500">
          <img src={eigththImage} alt="" />
          <div className="absolute -bottom-20 group-hover:bottom-3 start-3 end-3 transition-all duration-500">
            <a
              href="shop-cart.html"
              className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-slate-900 border-slate-900 text-white w-full rounded-md"
            >
              Add to Cart
            </a>
          </div>
          <ul className="list-none absolute top-[10px] end-4 opacity-0 group-hover:opacity-100 transition-all duration-500">
            <li>
              <a
                href="javascript:void(0)"
                className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-base text-center rounded-full bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white"
              >
                    <FontAwesomeIcon icon='fa-solid fa-heart' />
              </a>
            </li>
            <li className="mt-1">
              <a
                href="shop-item-detail.html"
                className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-base text-center rounded-full bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white"
              >
              <FontAwesomeIcon icon='fa-solid fa-eye' />
              </a>
            </li>
            <li className="mt-1">
              <a
                href="javascript:void(0)"
                className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-base text-center rounded-full bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white"
              >
              <FontAwesomeIcon icon='fa-solid fa-bookmark' />
              </a>
            </li>
          </ul>
        </div>
        <div className="mt-4">
          <a
            href="shop-item-detail.html"
            className="hover:text-indigo-600 text-lg font-semibold"
          >
            Gulab Jamun
          </a>
          <div className="flex justify-between items-center mt-1">
            <p className="text-green-600">
              $16.00 <del className="text-red-600">$21.00</del>
            </p>
            {/* <ul className="list-none absolute top-[10px] end-4 opacity-0 group-hover:opacity-100 transition-all duration-500">
            <li>
              <a
                href="javascript:void(0)"
                className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-base text-center rounded-full bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white"
              >
                    <FontAwesomeIcon icon='fa-solid fa-heart' />
              </a>
            </li>
            <li className="mt-1">
              <a
                href="shop-item-detail.html"
                className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-base text-center rounded-full bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white"
              >
              <FontAwesomeIcon icon='fa-solid fa-eye' />
              </a>
            </li>
            <li className="mt-1">
              <a
                href="javascript:void(0)"
                className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-base text-center rounded-full bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white"
              >
              <FontAwesomeIcon icon='fa-solid fa-bookmark' />
              </a>
            </li>
          </ul> */}
          </div>
        </div>
      </div>
      {/*end content*/}
    </div>
    {/*end grid*/}
    <div className="grid md:grid-cols-12 grid-cols-1 mt-8">
      <div className="md:col-span-12 text-center">
        <nav aria-label="Page navigation example">
          <ul className="inline-flex items-center -space-x-px">
            <li>
              <a
                href="#"
                className="w-[40px] h-[40px] inline-flex justify-center items-center text-slate-400 bg-white dark:bg-slate-900 rounded-s-lg hover:text-white border border-gray-100 dark:border-gray-700 hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"
              >
                <i className="uil uil-angle-left text-[20px] rtl:rotate-180 rtl:-mt-1" />
              </a>
            </li>
            <li>
              <a
                href="#"
                className="w-[40px] h-[40px] inline-flex justify-center items-center text-slate-400 hover:text-white bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-700 hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"
              >
                1
              </a>
            </li>
            <li>
              <a
                href="#"
                className="w-[40px] h-[40px] inline-flex justify-center items-center text-slate-400 hover:text-white bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-700 hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"
              >
                2
              </a>
            </li>
            <li>
              <a
                href="#"
                aria-current="page"
                className="z-10 w-[40px] h-[40px] inline-flex justify-center items-center text-white bg-indigo-600 border border-indigo-600"
              >
                3
              </a>
            </li>
            <li>
              <a
                href="#"
                className="w-[40px] h-[40px] inline-flex justify-center items-center text-slate-400 hover:text-white bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-700 hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"
              >
                4
              </a>
            </li>
            <li>
              <a
                href="#"
                className="w-[40px] h-[40px] inline-flex justify-center items-center text-slate-400 hover:text-white bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-700 hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"
              >
                5
              </a>
            </li>
            <li>
              <a
                href="#"
                className="w-[40px] h-[40px] inline-flex justify-center items-center text-slate-400 bg-white dark:bg-slate-900 rounded-e-lg hover:text-white border border-gray-100 dark:border-gray-700 hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"
              >
                <i className="uil uil-angle-right text-[20px] rtl:rotate-180 rtl:-mt-1" />
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    {/*end grid*/}
  </div>
</section>

  )
}