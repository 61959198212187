export const ACTION_TYPES = {
    // Website Default Home Data
    GET_WEBSITE_DEFAULT_DATA_BY_USER_LOCATION : "GET_WEBSITE_DEFAULT_DATA_BY_USER_LOCATION",

    //Restaurants
    ADD_NEW_RESTAURANT: "ADD_NEW_RESTAURANT",
    GET_RESTAURANTS_BY_USER_LOCATION: "GET_RESTAURANTS_BY_USER_LOCATION",
    GET_RESTAURANTS_BY_RESTAURANT_ID: "GET_RESTAURANTS_BY_RESTAURANT_ID",
    USER_FORMATTED_ADDRESS_FOR_RESTAURANT_LOCATION: "USER_FORMATTED_ADDRESS_FOR_RESTAURANT_LOCATION",

    
    //Products
    GET_DISCOUNTED_PRODUCTS: "GET_DISCOUNTED_PRODUCTS",
    GET_SINGLE_PRODUCT_BY_ID: "GET_SINGLE_PRODUCT_BY_ID",

    
    //Get-Cart-Length
    ADD_TO_CART: "ADD_TO_CART",
    GET_USER_CART: "GET_USER_CART",
    GET_CART_LENGTH: "GET_CART_LENGTH",

    //Categories
    GET_CATEGORIES: "GET_CATEGORIES",

    //progress
    PROGRESS_BAR_STATE: "PROGRESS_BAR_STATE",

    //login
    LOGIN: "LOGIN"
}